import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";

import Button from "../shared/Button";
import TextSeparator from "../shared/TextSeparator";

import "./About.css";

const About = () => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.9,
    };
    const onScreen = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowText(true);
        }
      });
    }
    let textObserver = new IntersectionObserver(onScreen, options);
    textObserver.observe(document.getElementById("about__header"));
    if (showText) {
      textObserver.unobserve(document.getElementById("about__header"));
    }
  }, [showText]);
  return (
    <section id="about" className="about">
      <div className="about__content">
        <div>
          <div
            id="about__header"
            className={
              showText ? "about__header about__appear" : "about__header"
            }
          >
            Hi!
          </div>
          <p
            className={
              showText
                ? "about__content-text about__appear"
                : "about__content-text"
            }
          >
            I'm an ex-graphic designer and DTP operator with 10+ years of experience. About 1 year ago I decided
            to change my career path. I learned UX/UI basics principles from Google UX Design Professional
            Certificate Course. Currently I'm studying UX Business Design at Tischner European University in Krakow.

          </p>
          <TextSeparator />
          <p
            className={
              showText
                ? "about__content-text about__appear"
                : "about__content-text"
            }
          >
            I'm looking for an entry level UX/UI job or internship.
          </p>
        </div>
      </div>
      <div className="about__button-section">
        <div className="about__button">
          <HashLink smooth to="/#projects">
            <Button text="projects" />
          </HashLink>
        </div>
        <div className="about__button">
          <HashLink smooth to="/#skills">
            <Button text="skills" />
          </HashLink>
        </div>
        {/* <div className="about__button">
          <HashLink smooth to="/#contact">
            <Button text="contact" />
          </HashLink>
        </div> */}
      </div>
    </section>
  );
};

export default About;
